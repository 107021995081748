

export function Root({ data }) {
    const cdn = "https://pusulaswiss.ch/upload/"
    //const api = "http://localhost:8080/"
     const api = "https://pusulaswiss.ch/"
    //const api = "http://flexelement.net/"

    switch (data?.type) {
        case "api": return `${api}api/api.php`
        case "posts": return `${cdn}posts/`
        case "writers": return `${cdn}writers/`
        case "galery": return `${cdn}galery/`
        case "banner": return `${cdn}banner/`
        case "currency": return `${api}api/currency/`
        default: return `${api}api/api.php`
    }
}
export function Urlgenerator({ data }) {
    return data.toString()
        .normalize('NFD') // Unicode normalize
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/[^a-zA-Z0-9ığüşöçİĞÜŞÖÇ\s]/g, '') // Remove non-alphanumeric characters except Turkish characters and spaces
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .toLowerCase() // Convert to lowercase
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\s-]/g, '') // Remove non-word characters
        .replace(/--+/g, '-') // Replace multiple hyphens with single hyphen
        .replace(/^-+/, '') // Remove leading hyphens
        .replace(/-+$/, ''); // Remove trailing hyphens
}
export function Dates({ data }) {
    if (data === "") {
        return ""
    }
    else {
        const d = data.split("-")
        const mount = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
        return d[2] + " " + mount[parseInt(d[1]) - 1] + " " + d[0];
    }

}
export function Unique({ data }) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < data; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}
export function Declare({ data }) {

    if (data?.type === "image") {
        const result = JSON.parse(data?.data)
        return JSON.parse(result.preview_image)["blob"]
    }



}
